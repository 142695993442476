import React, {useEffect, useState, useCallback} from 'react';
import './App.css';
import {FormControl, Select, MenuItem, CircularProgress} from "@mui/material";
import {styled} from '@mui/material/styles';
import {useDropzone} from "react-dropzone";
import axios from "axios";
import {Buffer} from "buffer";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const thisUrl = 'https://creativehub.global.com';

// const thisUrl = 'http://creativehub.global.test';
const maxSize = 5242880;

function App() {

    const handlerUrl = thisUrl + '/handler/';
    const dataUrl = thisUrl + '/data/';
    const defaultImageFileName = 'image1.jpg';

    const [data, setData] = useState({
        templates: [],
        categories: [],
        selectedCategoryId: 1,
        selectedTemplateId: 1,
        displayUrl: '',
        showProgress: false,
        imageFileName: defaultImageFileName,
        showDownload: false,
        isFileTooLarge: false
    });

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0 && fileRejections[0].file.size > maxSize) {
            setData({
                ...data,
                isFileTooLarge: true,
                showProgress: false,
                showDownload: false,
            })
            return;
        }

        setData({
            ...data,
            showProgress: true,
            showDownload: false,
            isFileTooLarge: false
        });
        axios({
            method: 'post',
            url: handlerUrl,
            headers: {
                "Content-type": "multipart/form-data"
            },
            data: {
                files: acceptedFiles,
                templateId: data.selectedTemplateId
            },
            responseType: "arraybuffer"
        })
            .then(res => {
                const renderImage = Buffer.from(res.data, 'binary').toString('base64');
                setData({
                    ...data,
                    showProgress: false,
                    displayUrl: `data:image/jpeg;base64,${renderImage}`,
                    showDownload: true,
                    isFileTooLarge: false
                })
            });
    }, [data])

    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
        onDrop, maxSize: maxSize, accept: {
            'image/jpeg': [],
            'image/png': []
        }
    })

    const Img = styled('img')({
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    });

    const getData = () => {
        fetch(dataUrl
            , {
                method: 'GET'
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonData) {
                const selectedTemplateId = returnFirstTemplateIdForCategory(data.selectedCategoryId, jsonData.templates);
                const imageName = jsonData.templates[selectedTemplateId]['displayFileName'];
                setData({
                    ...data,
                    templates: jsonData.templates,
                    categories: jsonData.categories,
                    selectedTemplateId: selectedTemplateId,
                    displayUrl: thisUrl + '/img/display/' + imageName,
                    imageFileName: imageName,
                });
            });
    }

    useEffect(() => {
        getData()
    }, [])

    const returnFirstTemplateIdForCategory = (categoryId, templates) => {
        const thisTemplates = templates ? templates : data.templates;
        const itemsForCat = Object.entries(thisTemplates).filter(template => Number(template[1]['categoryId']) === Number(categoryId));
        return itemsForCat[0][0];
    }

    const handleChange = (e) => {
        const imageName = data.templates[e.target.value]['displayFileName'];
        setData({
            ...data,
            selectedTemplateId: e.target.value,
            displayUrl: thisUrl + '/' + '/img/display/' + imageName,
            imageFileName: imageName,
            showDownload: false
        })
    }

    const handleCategorySelect = (e) => {
        let selectedTemplateId = returnFirstTemplateIdForCategory(e.target.value);
        setData({
            ...data,
            selectedCategoryId: e.target.value,
            selectedTemplateId: selectedTemplateId,
            displayUrl: thisUrl + '/' + '/img/display/' + data.templates[selectedTemplateId]['displayFileName'],
            showDownload: false
        })
    }

    return (
        <div className="App row"
             style={{textAlign: 'left', backgroundColor: '#F2F2F2', color: '#464545', paddingTop: '2em'}}>
            <div className="col-lg-5 col-xl-5 fade-in-up visible">
                <h2 style={{color: '#005AA7', marginTop: '0'}} className="title--xs">your artwork</h2>
                <p>Select a site from the dropdown menu below to visualise your campaign.</p>
                <p>You can then download and share your images.</p>
                <FormControl fullWidth>
                    <h4 id="select-category-label" style={{
                        textTransform: 'uppercase',
                        color: '#005AA7',
                        fontSize: '1em',
                        marginBottom: '0'
                    }}>Select a category</h4>
                    <Select
                        labelId="select-category-label"
                        id="select-category"
                        value={data.selectedCategoryId}
                        onChange={handleCategorySelect}
                        style={{border: 'none', backgroundColor: '#ffffff', marginBottom: '2em'}}
                    >
                        {data.categories && Object.entries(data.categories).map((value) => {
                            return (
                                <MenuItem style={{border: '0'}} key={value[0]} value={value[0]}>{value[1]}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth className='mb-3'>
                    <h4 id="select-template-label" style={{
                        textTransform: 'uppercase',
                        color: '#005AA7',
                        fontSize: '1em',
                        marginBottom: '0'
                    }}>Select a site</h4>
                    <Select
                        labelId="select-template-label"
                        id="select-template"
                        value={data.selectedTemplateId}
                        onChange={handleChange}
                        style={{border: 'none', backgroundColor: '#ffffff', marginBottom: '2em'}}
                    >
                        {Object.entries(data.templates).filter(template => Number(template[1]['categoryId']) === Number(data.selectedCategoryId)).map((value) => {
                            return (
                                <MenuItem key={value[0]} value={value[0]}>{value[1]['title']}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <a className="button" href="#" style={{display: 'flex'}}
                       {...getRootProps({templateId: data.selectedTemplateId})}>Upload image</a>
                    {data.showDownload && (
                        <a style={{backgroundColor: '#005AA7', color: '#ffffff', display: "flex"}}
                           className="button"
                           href={data.displayUrl}
                           download={data.imageFileName}>Download image</a>
                    )}
                    {data.isFileTooLarge && (
                        <div className="text-danger">
                            The file must be less than 5mb in size.
                        </div>
                    )}
                </div>
            </div>
            <div className="col-lg-7 col-xl-7 fade-in-up visible">
                <div style={{position: "relative"}}
                     className={'imageDisplay'}>
                    <input {...getInputProps()} />
                    {data.showProgress && (
                        <div style={{position: "absolute", width: "100%"}}>
                            <CircularProgress size={100} style={{position: "absolute"}}/>
                        </div>
                    )}
                    {Object.keys(data.templates).length > 1 && (
                        <Img {...getRootProps({templateId: data.selectedTemplateId})}
                             alt={data.templates[data.selectedTemplateId]['title']}
                             src={data.displayUrl}
                             style={{marginBottom: '0', width: '100%', opacity: data.showProgress ? '0.5' : 1}}
                        />
                    )}
                    {data.templates.length !== 0 && (
                        <div style={{backgroundColor: '#1a1a1a', padding: "1em", textAlign: 'left'}}>
                            <p className='text-white'>
                                <strong>{data.templates[data.selectedTemplateId]['title']}</strong></p>
                            <div className='text-white'>
                                <span>Recommended upload dimensions: {data.templates[data.selectedTemplateId].uploadDimensions.w + 'PX x '
                                    + data.templates[data.selectedTemplateId].uploadDimensions.h + 'PX'}</span>
                                <span style={{float: 'right'}}>
                                    <a style={{color: "#ffffff"}} href="https://global.com/outdoor/production-specs/"
                                       target="_blank">
                                        <ArrowOutwardIcon/>Production Specs</a>
                                </span>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}

export default App;
